<template>
  <div>
    <defaultFieldTypes
      :fieldAttributes="{ ...fieldAttributes, options: options }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :checkReadOnly="checkReadOnly"
      :value="value"
      :valueLine="valueLine"
      :hideDetails="hideDetails"
    ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: [
    "result",
    "fieldAttributes",
    "value",
    "field",
    "checkReadOnly",
    "valueLine",
    "hideDetails",
  ],
  created() {
    this.$nextTick(function () {
      this.$emit("revalidate");
    });
  },
  computed: {
    options() {
      let options = [];
      let metric = this.result.mainContent.valueLine.metric;
      if (
        typeof metric != "undefined" &&
        typeof this.result.mainContent.fields.metric.info[metric] !=
          "undefined" &&
        typeof this.result.mainContent.fields.metric.info[metric].tags !=
          "undefined"
      ) {
        options =
          this.result.mainContent.fields.metric.info[metric].tags[
            this.valueLine.tag
          ];
      }
      return options;
    },
  },
};
</script>